import {
	BankOutlined,
	BarChartOutlined,
	BookOutlined,
	ContainerOutlined,
	DollarCircleOutlined,
	EditOutlined,
	EnvironmentOutlined,
	FileDoneOutlined,
	FolderOpenOutlined,
	HomeOutlined,
	LaptopOutlined,
	LikeOutlined,
	MailOutlined,
	PicLeftOutlined,
	PlusCircleOutlined,
	PrinterOutlined,
	UnorderedListOutlined,
	UserOutlined,
} from "@ant-design/icons";

const Icons = {
	FolderOpenOutlined,
	EnvironmentOutlined,
	ContainerOutlined,
	PrinterOutlined,
	EditOutlined,
	BookOutlined,
	UserOutlined,
	BankOutlined,
	FileDoneOutlined,
	PlusCircleOutlined,
	HomeOutlined,
	UnorderedListOutlined,
	MailOutlined,
	LikeOutlined,
	PicLeftOutlined,
	DollarCircleOutlined,
	BarChartOutlined,
	LaptopOutlined,
};

export default Icons;
