import { FileImageOutlined } from "@ant-design/icons";
import { Alert, Card, Col, Form, Modal, Row, Upload } from "antd";
import { PropertyApi } from "api/PropertyApi";
import { t } from "i18n";

import React, { useEffect, useState } from "react";
import { MessagesService } from "services/Messages";
import { imageUploadCustomRequest } from "./UploadCustomRequest";

const getBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
function ValidateFile(file, img) {
	const isLargeFile = file.size && file.size > 5 * 1024 * 1024;
	if (isLargeFile) {
		throw new Error(
			`${file.name} ${t("Image size should not exceed 5mb.")}`
		);
	}
}
export default function Photos({ form }) {
	const [previewOpen, setPreviewOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState("");
	const [previewTitle, setPreviewTitle] = useState("");
	const [fileList, setFileList] = useState(form.getFieldValue().photos || []);
	const handleCancel = () => setPreviewOpen(false);
	useEffect(() => {
		setFileList(form.getFieldValue().photos || []);
	}, []);
	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}
		setPreviewImage(file.url || file.preview);
		setPreviewOpen(true);
		setPreviewTitle(
			file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
		);
	};
	const handleChange = ({ fileList, file }) => {
		try {
			if (file.status === "removed") {
				return;
			}

			let img = new Image(file);
			img.src = file?.response?.path;
			img.className = file.name;
			ValidateFile(file, img);
			beforeUpload(file);
			if (file.status === "done") {
				file.url = file.response.path;
				file.id = file.response.id;
				MessagesService.success();
			}
			setFileList(fileList);
			form.setFieldsValue({ photos: fileList });
		} catch (error) {
			MessagesService.error(error);
		}
	};
	const deleteFile = async (info) => {
		if (info?.id === undefined) {
			setFileList(
				fileList.filter(
					(item) => item?.originFileObj?.uid !== info?.uid
				)
			);
			form.setFieldsValue({ photos: fileList });
			return;
		}
		await PropertyApi.deletePhoto(info.id)
			.then(() => {
				MessagesService.success();
				setFileList(
					fileList.filter((item) => item.response.id !== info.id)
				);
				form.setFieldsValue({ photos: fileList });
			})
			.catch(MessagesService.error);
	};
	const uploadButton = (
		<div>
			<FileImageOutlined />
			<div
				style={{
					marginTop: 8,
				}}
			>
				{t("Upload")}
			</div>
		</div>
	);

	const beforeUpload = (file) => {
		return new Promise((resolve) => {
			// Check if we have a valid file object
			if (!(file instanceof File || file instanceof Blob)) {
				resolve(false);
				return;
			}

			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.addEventListener("load", (event) => {
				const _loadedImageUrl = event.target?.result;
				const image = document.createElement("img");
				image.src = _loadedImageUrl;

				image.addEventListener("load", () => {
					const { width, height } = image;
					if (width < 1024 || height < 512) {
						setFileList(
							fileList.filter(
								(item) => item?.originFileObj?.uid !== file?.uid
							)
						);
						form.setFieldsValue({
							photos: fileList.filter(
								(item) => item?.originFileObj?.uid !== file?.uid
							),
						});
						resolve(false);
						MessagesService.error(
							t("Image measurement must be greater than 1024x512")
						);
					}
					resolve(true);
				});
			});
		});
	};

	return (
		<div className="h-100">
			<div className="container d-flex flex-column justify-content-center h-100">
				<Row justify="">
					<Col xs={24} sm={24} md={24} lg={24}>
						<h2 className="my-3 mt-2">{t("Property photos")}</h2>

						<Card className="my-3">
							<div className="my-0 p-2">
								<Row justify="start">
									<Col xs={24} sm={24} md={24} lg={24}>
										<p>{t("Great photos")}</p>

										<Alert
											message={t("Warning")}
											description={t(
												"Image size must be less than 5mb. Dimensions must be greater than 1024x512."
											)}
											type="warning"
											showIcon
										/>

										<Form.Item
											name={"photos"}
											label={t("Upload photos")}
											className="mt-4"
											rules={[
												{
													required: true,
												},
												{
													validator(_, fileList) {
														if (
															fileList &&
															fileList.length > 0
														) {
															return Promise.resolve();
														}
														return Promise.reject(
															"Please enter 1 photos!"
														);
													},
												},
											]}
										>
											<Upload
												listType="picture-card"
												fileList={fileList}
												accept="image/png, image/jpeg"
												onPreview={handlePreview}
												onChange={handleChange}
												customRequest={
													imageUploadCustomRequest
												}
												multiple={true}
												onRemove={deleteFile}
												beforeUpload={beforeUpload}
											>
												{fileList.length >= 20
													? null
													: uploadButton}
											</Upload>
											<Modal
												open={previewOpen}
												title={previewTitle}
												footer={null}
												onCancel={handleCancel}
											>
												<img
													alt="example"
													style={{
														width: "100%",
													}}
													src={previewImage}
												/>
											</Modal>
										</Form.Item>
									</Col>
								</Row>
							</div>
						</Card>
					</Col>
				</Row>
			</div>
		</div>
	);
}
