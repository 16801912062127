import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { CalendarApi } from "api/CalendarApi";
import dayjs from "dayjs";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CalendarActions from "reduxs/calendar/calendar.actions";
import { calendarSelector } from "reduxs/calendar/calendar.selector";
import BookingCalendar from "./BookingCalendar";
import BookingTopCalendar from "./BookingTopCalendar";
import DataRanger from "./DataRanger";

function Calendar() {
	const calendar = useSelector(calendarSelector);
	const [showPricePerGuest, setShowPricePerGuest] = useState(false);
	const dispatch = useDispatch();

	function fetchCalendar() {
		!calendar.loading && dispatch(CalendarActions.updateLoading(true));
		CalendarApi.get({
			date: calendar.date_range,
		}).then((res) => {
			dispatch(CalendarActions.updateData(res));
		});
	}

	useEffect(() => {
		fetchCalendar();
		// eslint-disable-next-line
	}, [calendar.date_range]);

	useEffect(() => {
		if (calendar.loading) return;

		const elements = document.querySelectorAll(
			".sync-scroll div.ant-table-content"
		);

		function onScrolling(e: any) {
			elements.forEach((element) => {
				// exclude self element
				if (e.target !== element) {
					// change others scrolLeft
					element.scrollLeft = e.target.scrollLeft;
				}
			});
		}

		elements.forEach((element) => {
			element.addEventListener("scroll", onScrolling);
		});

		return () => {
			elements.forEach((element) => {
				element.removeEventListener("scroll", onScrolling);
			});
		};
	}, [calendar.loading]);

	const onChangeCheck = (e: CheckboxChangeEvent) => {
		setShowPricePerGuest(e.target.checked);
	};

	return (
		<div className="calendar">
			<h2>{t("Calendar")}</h2>
			<DataRanger
				onChange={(range) => {
					dispatch(CalendarActions.updateRangeData(range));
				}}
				value={calendar.date_range}
				disabledDate={(current: any) => {
					// Can not select days before today and today
					return current && current < dayjs().endOf("day");
				}}
			/>
			<Checkbox onChange={onChangeCheck} style={{ marginLeft: "20px" }}>
				{t("Pricing per guest")}
			</Checkbox>
			{calendar.data && (
				<>
					<BookingTopCalendar data={calendar.data} />
					<BookingCalendar
						data={calendar.data}
						fetchCalendar={fetchCalendar}
						loading={calendar.loading}
						showPricePerGuest={showPricePerGuest}
					/>
				</>
			)}
		</div>
	);
}

export default Calendar;
