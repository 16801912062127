import QueryString from "qs";
import mainApiInstence from "./mainApiInstence";

interface IListResponse<T> {
	items: T[];
	total: number;
	page: number;
	message: string;
}
export class ResourceApi {
	static async getList<T = any>(
		resource_endpoint: string,
		query?: { limit?: number; page?: number; [key: string]: any }
	): Promise<IListResponse<T>> {
		return mainApiInstence
			.get(`/${resource_endpoint}?${QueryString.stringify(query)}`)
			.then((r) => r.data);
	}
}
