import { DIR_LTR, NAV_TYPE_TOP, SIDE_NAV_LIGHT } from "constants/ThemeConstant";
import { t } from "i18n";
import { env } from "./EnvironmentConfig";

export const APP_NAME = `${t("Book hotel")}`;
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const APP_PREFIX_PATH = "/app";
export const ADMIN_PREFIX_PATH = "/admin";
export const APP_PROPERTY_PATH = `${t("property")}`;
export const AUTH_PREFIX_PATH = "/auth";

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: "en",
	navType: NAV_TYPE_TOP,
	topNavColor: "var(--primary-color)",
	headerNavColor: "",
	mobileNav: false,
	currentTheme: "light",
	direction: DIR_LTR,
};
