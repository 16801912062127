import { Button, Modal, Select } from "antd";
import { CalendarApi } from "api/CalendarApi";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CalendarActions from "reduxs/calendar/calendar.actions";
import { calendarSelector } from "reduxs/calendar/calendar.selector";
import Chat from "services/Chat";
import { margeCalendarUpdatedStrategy } from "utils/array";

const initialData = {
	open: false,
	name: "",
	room_id: 0,
	rate_id: 0,
	default_guest: 0,
	max_capacity: 0,
};

export default function EditOccupancyModal() {
	const [modal, setModal] = useState(initialData);
	const [newValue, setNewValue] = useState(0);
	const [loading, setLoading] = useState(false);
	const calendar = useSelector(calendarSelector);
	const dispatch = useDispatch();
	let itemList: any = [];

	function onCancel() {
		setModal(initialData);
	}

	async function fetchCalendar() {
		!calendar.loading && dispatch(CalendarActions.updateLoading(true));
		await CalendarApi.get({
			date: calendar.date_range,
		})
			.then((res) => {
				dispatch(CalendarActions.updateData(res));
				setModal(initialData);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	}
	async function fetchAccupancy() {
		if (modal.default_guest !== newValue && modal.default_guest > 0) {
			setLoading(true);
			await CalendarApi.updateStrategy({
				room_id: modal.room_id,
				rate_id: modal.rate_id,
				default_guest: newValue,
				price_by_guest_strategy: [],
			})
				.then((res: any) => {
					const new_calendar = margeCalendarUpdatedStrategy({
						...res,
						room_id: modal.room_id,
						rate_id: modal.rate_id,
						calendar: calendar,
					});
					dispatch(CalendarActions.updateData(new_calendar));
					setLoading(false);
				})
				.catch((err) => {
					setLoading(false);
					console.log(err);
				});
		}
	}

	async function onFinish() {
		setLoading(true);
		await fetchAccupancy();
		await fetchCalendar();
	}
	const handleChange = (value: string) => {
		setNewValue(Number(value) + 1);
	};
	useEffect(() => {
		Chat.inbox((info) => {
			setModal({
				open: info.open,
				...info,
			});
			setNewValue(info.default_guest);
		}, "accupancy_popup");
	}, []);

	itemList.push(
		Array.from({ length: modal.max_capacity }, (_, key) => {
			return (
				<Select.Option key={String(key)} value={String(key)}>
					{key + 1}
				</Select.Option>
			);
		})
	);
	return (
		<Modal
			className={`accupancy_popup`}
			title={
				<>
					<h3 className="m-0 font-weight-bold">
						{t("Edit pricing per guest")}
					</h3>
					<h5 className="m-0 font-weight-normal">{modal.name}</h5>
				</>
			}
			open={modal.open}
			onCancel={onCancel}
			destroyOnClose
			footer={
				<div className="d-flex">
					<Button
						type="primary"
						disabled={modal.default_guest === newValue}
						onClick={onFinish}
						loading={loading}
					>
						{t("Save")}
					</Button>
					<Button onClick={onCancel} type="ghost">
						{t("Cancel")}
					</Button>
				</div>
			}
		>
			<h4>
				<b>
					{t(
						"What's the base occupancy you want to use for this room?"
					)}
				</b>
			</h4>
			<h5>{t("Your prices can change")}</h5>
			<span className="d-block mt-3">{t("Base occupancy")}</span>
			<Select
				className="w-100"
				defaultValue={`${modal.default_guest - 1}`}
				size="large"
				onChange={handleChange}
			>
				{itemList}
			</Select>
		</Modal>
	);
}
