import {
	LOADER,
	UPDATE_CALENDAR_DATA,
	UPDATE_CALENDAR_RANGE_DATE,
} from "reduxs/calendar/calendar.cons";
import { CalendarResponse } from "types/calendar.types";
import { getDateAfter, getDateISOString, getRangeDate } from "utils/date";

export const initialState: CalendarResponse.CalendarState = {
	data: {
		date_range: getRangeDate([
			getDateISOString(),
			getDateISOString(getDateAfter(30)),
		]),
		rooms: [],
	},
	date_range: [getDateISOString(), getDateISOString(getDateAfter(30))],
	loading: true,
};

export function calenderReducer(
	state = initialState,
	action: {
		type: string;
		payload: any;
	}
): CalendarResponse.CalendarState {
	switch (action.type) {
		case UPDATE_CALENDAR_DATA:
			return {
				...state,
				loading: false,
				data: action.payload,
			};
		case UPDATE_CALENDAR_RANGE_DATE:
			return {
				...state,
				date_range: action.payload,
			};
		case LOADER:
			return {
				...state,
				loading: action.payload,
			};
		default:
			return state;
	}
}
