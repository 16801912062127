import { Card, Checkbox, Col, Form, Input, Row, Select } from "antd";
import { ResourceApi } from "api/ResourceApi";
import { t } from "i18n";

import React, { useEffect, useState } from "react";
import { getNameTranslation } from "services/ContentTranslation/get-translations";
// import { t } from "i18n";

export default function Payments({ current, setCurrent }) {
	const [paymentCard, setPaymentCard] = useState([]);
	const [payments, setpayments] = useState([]);
	const [invoice_recipient, setinvoice_recipient] = useState(1);
	const [regions, setRegions] = useState([]);

	const onChange = (checkedValues) => {
		setPaymentCard(checkedValues);
	};
	useEffect(() => {
		ResourceApi.getList("payment-methods", {
			page: 1,
			limit: 100,
		}).then((data) => setpayments(data.items));
	}, []);
	useEffect(() => {
		ResourceApi.getList("regions", { limit: 50 }).then((r) => {
			setRegions(r.items);
		});
	}, []);
	return (
		<div className="h-100">
			<div className="container d-flex flex-column justify-content-center h-100">
				<Row justify="">
					<Col xs={24} sm={24} md={24} lg={24}>
						<h2 className="my-3 mt-2">{t("Payments")}</h2>
						<p>{t("Specify the types")}</p>
						<Card className="my-3">
							<div className="my-0 p-2">
								<Row justify="start">
									<Col xs={24} sm={24} md={20} lg={12}>
										<h3>{t("Guest payment options")}</h3>
										{/* <Form.Item
											label={t(
												"Can you charge credit cards at the property?"
											)}
											className="mt-4"
											labelCol={{
												span: 24,
											}}
											rules={[
												{
													required: true,
													message: `${t(
														"Can you charge credit cards at the property?"
													)}`,
												},
											]}
										>
											<Radio.Group
												buttonStyle="solid"
												onChange={(e) => onChange(e)}
												size="large"
											>
												<Radio.Button value="true">
													{t("Yes")}
												</Radio.Button>
												<Radio.Button value="false">
													{t("No")}
												</Radio.Button>
											</Radio.Group>
										</Form.Item> */}

										{/* {paymentCard === "true" ? ( */}
										<Form.Item
											name={[
												"payment",
												"payment_method_ids",
											]}
											className="mt-4"
											labelCol={{
												span: 24,
											}}
											// rules={[
											// 	{
											// 		validator: (_, value) =>
											// 			value.length > 0
											// 				? Promise.resolve()
											// 				: Promise.reject(
											// 						new Error(
											// 							`${t(
											// 								"Should accept agreement"
											// 							)}`
											// 						)
											// 				  ),
											// 	},
											// ]}
										>
											<Checkbox.Group
												style={{
													width: "100%",
												}}
												onChange={onChange}
											>
												<Row>
													{payments.map((item) => {
														return (
															<Col
																key={item.id}
																span={12}
															>
																<Checkbox
																	value={
																		item.id
																	}
																	className="mb-2"
																>
																	<img
																		src={
																			item
																				?.image
																				?.path
																		}
																		style={{
																			width: "50px",
																			height: "25px",
																			objectFit:
																				"contain",
																			marginRight:
																				"10px",
																		}}
																		alt=""
																	/>
																	{getNameTranslation(
																		item
																	)}
																</Checkbox>
															</Col>
														);
													})}
												</Row>
											</Checkbox.Group>
										</Form.Item>
										{/* ) : (
											""
										)} */}

										{/* {paymentCard ? } */}
										<p>{t("These are the bed")}</p>
									</Col>
								</Row>
							</div>
						</Card>

						<Card className="my-3">
							<div className="my-0 p-2">
								<Row justify="start">
									<Col xs={24} sm={24} md={24} lg={24}>
										<h3>{t("Commission payments")}</h3>
										<Row>
											<Col
												xs={24}
												sm={24}
												md={16}
												lg={16}
											>
												<p>{t("At the beginning")}</p>
											</Col>
											<Col
												xs={24}
												sm={24}
												md={16}
												lg={8}
												style={{ textAlign: "right" }}
											>
												<p>
													{t("Commission percentage")}
													:
												</p>
												<h2
													style={{
														color: "green",
														marginTop: "-15px",
														fontSize: "30px",
													}}
												>
													15%
												</h2>
											</Col>
											<Col
												xs={24}
												sm={24}
												md={12}
												lg={12}
											>
												<Form.Item
													label={t(
														"What name should be placed on the invoice (e.g. legal/company name)?"
													)}
													name={[
														"payment",
														"invoice_recipient",
													]}
													labelCol={{
														span: 24,
													}} // tooltip="Siz foydalanadigan emailni kiriting biz tastiqlash kod jo'natamiz!"
													rules={[
														{
															required: true,
															message:
																"Oops! Please fill in the company name",
														},
													]}
													initialValue={1}
												>
													<Select
														onChange={(e) => {
															setinvoice_recipient(
																e
															);
														}}
														allowClear
													>
														<Select.Option
															value={0}
														>
															{t(
																"company_name_create"
															)}
														</Select.Option>
														<Select.Option
															value={1}
														>
															{t("property_name")}
														</Select.Option>
													</Select>
												</Form.Item>
											</Col>
										</Row>
										<Row>
											{invoice_recipient === 0 ? (
												<Col
													xs={24}
													sm={24}
													md={24}
													lg={24}
												>
													<Form.Item
														label={t(
															"What name should be placed on the invoice (e.g. legal/company name)?"
														)}
														name={[
															"payment",
															"legal_company_name",
														]}
														labelCol={{
															span: 24,
														}} // tooltip="Siz foydalanadigan emailni kiriting biz tastiqlash kod jo'natamiz!"
														rules={[
															{
																required: true,
																message: `${t(
																	"Oops! Please fill in the company name"
																)}`,
															},
														]}
													>
														<Input
															className="p-2"
															placeholder="Legal/company name"
														/>
													</Form.Item>
													<div className="my-0 p-lg-2 p-1">
														<Row justify="start">
															<Col
																xs={24}
																sm={24}
																md={20}
																lg={10}
															>
																<h3>
																	{t(
																		"Where is your property located?"
																	)}
																</h3>
																<Form.Item
																	name={[
																		"payment",
																		"legal_address",
																		"addressLine",
																	]}
																	label={t(
																		"Street address!"
																	)}
																	className="mt-4"
																	labelCol={{
																		span: 24,
																	}} // tooltip="Siz foydalanadigan emailni kiriting biz tastiqlash kod jo'natamiz!"
																	rules={[
																		{
																			required: true,
																			message: `${t(
																				"Street address is required!"
																			)}`,
																		},
																		{
																			min: 5,
																			message: `${t(
																				"Street address be longer than 5 characters!"
																			)}`,
																		},
																	]}
																>
																	<Input className=" p-2" />
																</Form.Item>
																<Form.Item
																	name={[
																		"payment",
																		"legal_address",
																		"addressLine2",
																	]}
																	label={t(
																		"Address line 2"
																	)}
																	className="mt-4"
																	labelCol={{
																		span: 24,
																	}} // tooltip="Siz foydalanadigan emailni kiriting biz tastiqlash kod jo'natamiz!"
																>
																	<Input className="p-2" />
																</Form.Item>
																<Form.Item
																	name={[
																		"payment",
																		"legal_address",
																		"city",
																	]}
																	label={t(
																		"Region"
																	)}
																	className="mt-4"
																	labelCol={{
																		span: 24,
																	}}
																	rules={[
																		{
																			required: true,
																			message: `${t(
																				"Region is required!"
																			)}`,
																		},
																	]}
																>
																	<Select
																		showSearch
																		placeholder={t(
																			"Region"
																		)}
																		options={regions.map(
																			(
																				r
																			) => ({
																				label: getNameTranslation(
																					r
																				),
																				value: r.id,
																			})
																		)}
																	/>
																</Form.Item>

																<Form.Item
																	name={[
																		"payment",
																		"legal_address",
																		"country",
																	]}
																	hidden
																	initialValue={
																		"uz"
																	}
																>
																	<Input></Input>
																</Form.Item>
															</Col>
														</Row>
													</div>
												</Col>
											) : (
												""
											)}
										</Row>
									</Col>
								</Row>
							</div>
						</Card>

						<Card className="my-3">
							<div className="my-0 p-2">
								<Row justify="start">
									<Col xs={24} sm={24} md={20} lg={18}>
										<h2>
											{t(
												"You’re almost done – just a few final things to consider"
											)}
										</h2>
										<h3>
											{t("Your availability to guests")}
										</h3>
										<p>{t("to help")}</p>
										<h3>
											{t(
												"To complete your registration, please tick the boxes below:"
											)}
										</h3>
										<Form.Item
											name={["payment", "accept_terms"]}
											valuePropName="checked"
											rules={[
												{
													validator: (_, value) =>
														value
															? Promise.resolve()
															: Promise.reject(
																	new Error(
																		`${t(
																			"Should accept agreement"
																		)}`
																	)
															  ),
												},
											]}
										>
											<Checkbox>
												{t("I certify")}
											</Checkbox>
										</Form.Item>

										<Form.Item
											name={[
												"payment",
												"certified_legality",
											]}
											valuePropName="checked"
											rules={[
												{
													validator: (_, value) =>
														value
															? Promise.resolve()
															: Promise.reject(
																	new Error(
																		`${t(
																			"Should accept agreement"
																		)}`
																	)
															  ),
												},
											]}
										>
											<Checkbox>
												{t("I have read")}{" "}
												<a
													target="_blank"
													href="/terms-of-use"
													rel="noreferrer"
												>
													{t(
														"General Delivery Terms"
													)}
												</a>{" "}
												{t("and")}{" "}
												<a
													href="/terms-of-use"
													target="_blank"
													rel="noreferrer"
												>
													{t("Privacy Statement")}{" "}
												</a>
												{t("General Delivery Termss")}
											</Checkbox>
										</Form.Item>
									</Col>
								</Row>
							</div>
						</Card>
					</Col>
				</Row>
			</div>
		</div>
	);
}
