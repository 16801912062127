import { t } from "i18n";
import React from "react";

export const PageHeader = ({ display }) => {
	return display ? (
		<div className="app-page-header">
			<h3 className="mb-0 mr-3 font-weight-semibold">{t("Home")}</h3>
		</div>
	) : null;
};

export default PageHeader;
