import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";

function BackButton({ back_to }) {
	const history = useHistory();

	return (
		<Button
			size="small"
			style={{
				marginRight: 10,
			}}
			type="primary"
			ghost
			icon={<ArrowLeftOutlined />}
			onClick={() => {
				if (back_to) {
					history.push(back_to);
				} else {
					history.goBack();
				}
			}}
		/>
	);
}

export default BackButton;
