import { Select } from "antd";
import { changeLanguage, getLang } from "i18n";

import React from "react";

export default function Footer() {
	const mediaMatch = window.matchMedia("(min-width: 500px)");
	return (
		<footer className="footer" style={{ height: 100 }}>
			<span>
				<span className="font-weight-semibold">
					&copy; Copyright Bookhotel.uz{" "}
					{` ${new Date().getFullYear()}`}{" "}
				</span>{" "}
			</span>
			{/* <div>
				<a className="text-gray" href="/#" onClick={e => e.preventDefault()}>Term & Conditions</a>
				<span className="mx-2 text-muted"> | </span>
				<a className="text-gray" href="/#" onClick={e => e.preventDefault()}>Privacy & Policy</a>
			</div> */}

			<Select
				onChange={changeLanguage}
				defaultValue={getLang()}
				style={
					mediaMatch.matches
						? {
								color: "#000",
								padding: 5,
								marginRight: 80,
						  }
						: {}
				}
				options={[
					// {
					// 	value: "uz",
					// 	label: "O'zbekcha",
					// },
					{
						value: "ru",
						label: "Русский",
					},
					{
						value: "en",
						label: "English",
					},
				]}
			/>
		</footer>
	);
}
