import { Card, Form } from "antd";
import { PropertyApi } from "api/PropertyApi";
import { ResourceApi } from "api/ResourceApi";
import BackButton from "components/shared-components/BackButton";
import Loading from "components/shared-components/Loading";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { authenticated } from "reduxs/actions/Auth";
import { getTOKEN } from "reduxs/reducers/Auth";
import { MessagesService } from "services/Messages";
import SettingsForm from "./components/SettingsForm";

export default function Settings() {
	const [fileList, setFileList] = useState([]);
	const [user, setUser] = useState();
	const [loading, setloading] = useState(false);
	const dispatch = useDispatch();

	const [form] = Form.useForm();

	useEffect(() => {
		setloading(true);
		ResourceApi.getList("property/user")
			.then((data) => {
				setloading(false);
				setUser(data);
				data.photo_id &&
					setFileList([{ url: data.photo.path, id: data.photo_id }]);
			})
			.catch((err) => {
				MessagesService.error("error", err);
				setloading(false);
			});
	}, []);

	function ValidateFile(file) {
		const isLargeFile = file.size && file.size > 5 * 1024 * 1024;

		if (isLargeFile) {
			throw new Error(
				`${file.name} ${t("Image size should not exceed 5mb.")}`
			);
		}
	}
	const deleteFile = async (info) => {
		await PropertyApi.deletePhoto(info)
			.then(() => {
				MessagesService.success();
				setFileList(fileList.filter((item) => item.id !== info));
			})
			.catch(MessagesService.error);
	};
	const onChange = async (info) => {
		try {
			ValidateFile(info.file);
			if (info.file.status === "done") {
				info.file.url = info.file.response.path;
				info.file.id = info.file.response.id;
			}
			setFileList(info.fileList);
		} catch (error) {
			MessagesService.error(error);
		}
	};

	const onPreview = async (file) => {
		let src = file.url;
		if (!src) {
			src = await new Promise((resolve) => {
				const reader = new FileReader();
				reader.readAsDataURL(file.originFileObj);
				reader.onload = () => resolve(reader.result);
			});
		}
		const image = new Image();
		image.src = src;
		const imgWindow = window.open(src);
		imgWindow?.document.write(image.outerHTML);
	};
	// HANDLERS

	const onFinish = (values) => {
		PropertyApi.patchProperty({
			first_name: form.getFieldValue().first_name,
			last_name: form.getFieldValue().last_name,
			last_name: form.getFieldValue().last_name,
			oldPassword: form.getFieldValue().old_password,
			password: form.getFieldValue().new_password,
			photo_id: fileList[0]?.url
				? fileList[0]?.id
				: fileList[0]?.response.id || null,
		})
			.then((data) => {
				if (data.status === 200) {
					dispatch(authenticated(getTOKEN(), data?.data));
					MessagesService.success();
				}
			})
			.catch((err) => {
				if (err.response.data.status === 422) {
					MessagesService.error(t("Old password is incorrect"));
				}
			});
	};

	if (loading || !user) {
		return <Loading />;
	}
	return (
		<div>
			<Card>
				<h2>
					<BackButton />
					{t("Settings")}
				</h2>
				<SettingsForm
					onChange={onChange}
					onPreview={onPreview}
					fileList={fileList}
					deleteFile={deleteFile}
					onFinish={onFinish}
					initialValue={user}
					form={form}
				/>
			</Card>
		</div>
	);
}
