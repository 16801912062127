import { Col, Row, Table, Typography } from "antd";
import { PropertiesAdminApi } from "api/admin/PropertiesAdminApi";
import mainApiInstence from "api/mainApiInstence";
import { ResourceApi } from "api/ResourceApi";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { MessagesService } from "services/Messages";
import { FilterButton } from "./FilterButton";
import { FilterDrawer } from "./FilterDrawer";
import { getPropertiesFilterFields } from "./getPropertiesFilterFields";
import { getPropertyColumns } from "./getPropertyColumns";
import { initial_filter } from "./initial_filter";
import { AntTableSortMapRevert } from "./property.const";

export default function AllProperties({ match }) {
	const [data, setData] = useState({ items: [], total: 0 });
	const [isLoading, setIsLoading] = useState(false);
	const [isFetching, setIsFetching] = useState(true);
	const [regions, setRegions] = useState([]);
	const [categories, setCategories] = useState();
	const [filter, setFilter] = useState(initial_filter);

	// Initial data fetch
	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				const [regionsData, categoriesData] = await Promise.all([
					ResourceApi.getList("regions", { limit: 50 }),
					mainApiInstence.get("/property/categories"),
				]);

				setRegions(regionsData.items);
				setCategories(categoriesData.data.items);
			} catch (error) {
				console.error("Failed to fetch initial data:", error);
				MessagesService.error(t("Failed to load initial data"));
			} finally {
				setIsFetching(false);
			}
		};

		fetchInitialData();
		fetchProperties(filter);
	}, []);

	const fetchProperties = async (filterParams) => {
		setIsLoading(true);
		try {
			const response = await PropertiesAdminApi.getList(filterParams);
			setData(response);
		} catch (error) {
			console.error("Failed to fetch properties:", error);
			MessagesService.error(t("Failed to load properties"));
		} finally {
			setIsLoading(false);
		}
	};

	const handleFilterSubmit = (newFilter) => {
		const updatedFilter = { ...filter, ...newFilter };
		setFilter(updatedFilter);
		fetchProperties(updatedFilter);
	};

	const handleTableChange = (pagination, _, sort) => {
		const updatedFilter = {
			...filter,
			page: pagination.current,
			limit: pagination.pageSize,
			order: AntTableSortMapRevert[sort.order],
			order_by: sort.field,
		};
		setFilter(updatedFilter);
		fetchProperties(updatedFilter);
	};

	return (
		<div>
			<Row>
				<Col span={12}>
					<Typography.Title level={1}>
						{t("Properties")}
					</Typography.Title>
				</Col>
				<Col span={12} className="d-flex justify-content-end">
					<FilterButton
						onResetFilter={() => {
							setFilter(initial_filter);
							fetchProperties(initial_filter);
						}}
					/>
				</Col>
				{!isFetching && regions && categories && (
					<FilterDrawer
						filter={filter}
						onSubmit={handleFilterSubmit}
						fields={getPropertiesFilterFields(regions, categories)}
					/>
				)}
			</Row>
			<div style={{ maxWidth: "100%", overflow: "auto" }}>
				<Table
					className="ant-card-bordered rounded"
					onChange={handleTableChange}
					loading={isLoading}
					dataSource={data?.items}
					columns={getPropertyColumns(match, filter)}
					rowKey="id"
					virtual={false}
					scroll={{ x: 1300, scrollToFirstRowOnChange: true }}
					pagination={{
						current: filter.page,
						total: data.total,
						pageSize: filter.limit,
						showSizeChanger: false,
					}}
					style={{ width: "100%" }}
				/>
			</div>
		</div>
	);
}
