import {
	LOADER,
	UPDATE_CALENDAR_DATA,
	UPDATE_CALENDAR_RANGE_DATE,
} from "reduxs/calendar/calendar.cons";
import { CalendarResponse } from "types/calendar.types";

const CalendarActions = {
	updateData: (data: CalendarResponse.CalendarResponse) => {
		return {
			type: UPDATE_CALENDAR_DATA,
			payload: data,
		};
	},
	updateRangeData: (range: string[]) => {
		return {
			type: UPDATE_CALENDAR_RANGE_DATE,
			payload: range,
		};
	},

	updateLoading: (loading: boolean) => {
		return {
			type: LOADER,
			payload: loading,
		};
	},
};

export default CalendarActions;
