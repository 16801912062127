import { CalendarApi } from "api/CalendarApi";
import Loading from "components/shared-components/Loading";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CalendarActions from "reduxs/calendar/calendar.actions";
import { calendarSelector } from "reduxs/calendar/calendar.selector";
import Chat from "services/Chat";
import { CalendarUpdateFieldNameEnum } from "types/calendar.types";
import { havePriceActive, mergeUpdatedDate } from "utils/array";
import { isSunday } from "utils/date";

export function RoomCell({
	room,
	dates,
	dateIndex,
}: {
	room?: any;
	dates?: any;
	dateIndex: number;
}) {
	const calendar = useSelector(calendarSelector);
	const dispatch = useDispatch();
	const currentdate = dates[dateIndex];
	const [loading, setLoading] = useState({
		loadingIndex: currentdate,
		loading: false,
	});
	const record = room?.dates;

	const emptyData = {
		active: false,
		count: 0,
		currentdate: currentdate,
		net_booked: 0,
		room: room,
		room_id: room.id,
	};
	const data = {
		...record[currentdate],
		room: room,
		currentdate,
	};

	const dayInfo = havePriceActive(record[currentdate] ? data : emptyData);

	const isActive = !record[currentdate]?.active
		? record[currentdate]?.active
		: dayInfo.active;
	const handleKeyboardEvent = (e: any, data: any) => {
		const key = "Enter";

		const isWrong = Number(e.currentTarget.value) > 0;
		const isDifferent = Number(e.currentTarget.value) !== data.count;
		if (
			(e._reactName === "onBlur" || e.key === key) &&
			isWrong &&
			isDifferent
		) {
			setLoading({ ...loading, loading: true });
			return CalendarApi.update({
				updates: [
					{
						field_name: CalendarUpdateFieldNameEnum.rooms_to_sell,
						field_value: Number(e.currentTarget.value),
					},
				],
				room_id: data.room.id,
				from_date: data.currentdate,
				until_date: data.currentdate,
			})
				.then((res) => {
					const calendar_data = mergeUpdatedDate(calendar.data, res);
					dispatch(CalendarActions.updateData(calendar_data));
					setLoading({ ...loading, loading: false });
				})
				.catch((err) => {
					console.log(err);
					setLoading({ ...loading, loading: false });
				});
		}
	};

	function onChange(e: React.ChangeEvent<HTMLInputElement>) {
		if (/^0/.test(e.target.value)) {
			e.target.value = e.target.value.replace(/^0/, "");
		}
	}
	return (
		<div
			className={
				`room-cell ${
					isSunday(new Date(currentdate)) ? "border-right" : ""
				}` +
				(isActive
					? ""
					: " unavailable" + (data.count <= 0 ? `empty` : ``))
			}
			onClick={() => {
				Chat.send(
					{ ...data, dayInfo: dayInfo, open: true },
					"aviability_popup"
				);
			}}
		>
			{loading.loadingIndex === currentdate && loading.loading ? (
				<Loading size={20} />
			) : (
				<div className="tooltip">
					<input
						key={data.count}
						type="number"
						max="10"
						min={1}
						maxLength={1000}
						defaultValue={data.count}
						onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
							handleKeyboardEvent(e, data)
						}
						onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
							handleKeyboardEvent(e, data)
						}
						onChange={onChange}
						onClick={(e) => {
							e.stopPropagation();
						}}
					/>
					<span className="tooltiptext">
						{data.count ? data.count : "empty"}
					</span>
				</div>
			)}
		</div>
	);
}
