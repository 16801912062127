export function getDayLaterDate(days: number, date = new Date()) {
	date.setDate(date.getDate() + days);
	return date;
}

export function getRangeDate(date: [string, string]) {
	const from_n = new Date(date[0]);

	const ranges = [date[0]];

	if (date[0] === date[1]) {
		return ranges;
	}

	for (let i = 0; i < 366; i++) {
		from_n.setDate(from_n.getDate() + 1);
		const day = from_n.toISOString().slice(0, 10);

		ranges.push(day);

		if (day === date[1]) {
			return ranges;
		}
	}

	return ranges;
}

export function getDateAfter(day: number, date = new Date()) {
	date.setDate(date.getDate() + day);

	return date;
}

export function getDateISOString(date = new Date()) {
	return date.toISOString().slice(0, 10);
}

export function isSunday(date = new Date()) {
	return date.getDay() === 0;
}
