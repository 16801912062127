import { Button, Result, Spin } from "antd";
import { PropertyApi } from "api/PropertyApi";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { MessagesService } from "services/Messages";

export default function ConnectTelegram() {
	const location = useLocation();
	const [result, setResult] = useState();
	const [loading, setLoading] = useState(true);
	let chatId;
	useEffect(() => {
		chatId = new URLSearchParams(location.search).get("chat-id");
		PropertyApi.patchProperty({
			telegram_group_id: parseInt(chatId),
		})
			.then(() => {
				setResult(true);
				setLoading(false);
                
				MessagesService.success(
					"Telegram group connected successfully!!!"
				);
			})
			.catch(() => {
				setResult(false);
				setLoading(false);
			});
	}, []);

	return (
		<div>
			<div>
				{loading ? (
					<Result
						icon={<Spin size="large" />}
						title="Connecting to Telegram..."
					/>
				) : result ? (
					<Result
						status="success"
						title="Telegram notification successfully connected"
						subTitle="We will send you any notifications regarding your property via telegram!!!"
						extra={[
							<Button type="primary" key="console">
								<Link to={"/app/dashboard"}>Dashboard</Link>
							</Button>,
						]}
					/>
				) : (
					<Result
						title="There was a problem connecting, please try again!!!"
						extra={
							<Button type="primary" key="console">
								<Link to={"/app/dashboard"}>Dashboard</Link>
							</Button>
						}
					/>
				)}
			</div>
		</div>
	);
}
