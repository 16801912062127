export enum CalendarUpdateFieldNameEnum {
	status_day = "status_day",
	price = "price",
	rooms_to_sell = "rooms_to_sell",
	status_rate = "status_rate",
}

export class RatePriceRecord {
	rate_id: number;

	price?: number;

	status?: boolean;

	rates?: RatePriceRecord;

	rooms_to_sell?: number;
}

export class UpdateCalendarRecord {
	field_name: CalendarUpdateFieldNameEnum;

	field_value: any;

	rate_id?: number;
}

export class UpdateCalendarDto {
	updates: UpdateCalendarRecord[];

	room_id: number;

	from_date: string;

	until_date: string;
}

export declare module CalendarResponse {
	export interface RoomAviabilityRecord {
		room_id: number;
		count: number;
		day: number;
		net_booked: number;
		min_price: number;
		active: boolean;
	}

	export interface RoomRateRecord {
		price: number;
		active: boolean;
	}

	export interface Rate {
		id: number;
		name: string;
		strategy: Object[];
		default_guest: number;
		dates: Record<string, RoomRateRecord>;
	}

	export interface Room {
		id: number;
		name: string;
		dates: Record<string, RoomAviabilityRecord>;
		rates: Rate[];

	}

	export interface CalendarState {
		date_range: string[];
		data: CalendarResponse.CalendarResponse;
		loading: boolean;
	}

	export interface CalendarResponse {
		date_range: string[];
		rooms: Room[];
	}
}
