import SideNav from "components/layout-components/SideNav";
import TopNav from "components/layout-components/TopNav";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
// import Loading from 'components/shared-components/Loading';
import HeaderNav from "components/layout-components/HeaderNav";
import MobileNav from "components/layout-components/MobileNav";
import PageHeader from "components/layout-components/PageHeader";
// import Footer from "components/layout-components/Footer";
import { Alert, Button, Grid, Layout } from "antd";
import AppViews from "../../views/app-views";

import { ResourceApi } from "api/ResourceApi";
import Footer from "components/layout-components/Footer";
import navigationConfig from "configs/NavigationConfig";
import {
	DIR_LTR,
	DIR_RTL,
	NAV_TYPE_SIDE,
	NAV_TYPE_TOP,
	SIDE_NAV_COLLAPSED_WIDTH,
	SIDE_NAV_WIDTH,
} from "constants/ThemeConstant";
import { t } from "i18n";
import { useHistory } from "react-router-dom";
import { authenticated } from "reduxs/actions/Auth";
import { getTOKEN } from "reduxs/reducers/Auth";
import utils from "utils";

const { Content } = Layout;
const { useBreakpoint } = Grid;

export const AppLayout = ({ navCollapsed, navType, location, direction }) => {
	const currentRouteInfo = utils.getRouteInfo(
		navigationConfig,
		location.pathname
	);
	const [user, setUser] = useState({});
	const dispatch = useDispatch();

	const screens = utils.getBreakPoint(useBreakpoint());
	const isMobile = screens.length === 0 ? false : !screens.includes("lg");
	const isNavSide = navType === NAV_TYPE_SIDE;
	const isNavTop = navType === NAV_TYPE_TOP;
	const getLayoutGutter = () => {
		if (isNavTop || isMobile) {
			return 0;
		}
		return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
	};

	const history = useHistory();

	const getLayoutDirectionGutter = () => {
		if (direction === DIR_LTR) {
			return { paddingLeft: getLayoutGutter() };
		}
		if (direction === DIR_RTL) {
			return { paddingRight: getLayoutGutter() };
		}
		return { paddingLeft: getLayoutGutter() };
	};

	const userData = useSelector((state) => state.auth.user);
	useEffect(() => {
		userData?.role?.system !== 1 &&
			ResourceApi.getList("property/user").then((data) => {
				setUser(data);
				dispatch(authenticated(getTOKEN(), data));
			});
	}, []);

	return (
		<Layout>
			<HeaderNav isMobile={isMobile} />
			{isNavTop && !isMobile ? (
				<TopNav routeInfo={currentRouteInfo} />
			) : null}
			<Layout className="app-container">
				{isNavSide && !isMobile ? (
					<SideNav routeInfo={currentRouteInfo} />
				) : null}
				<Layout
					className="app-layout"
					style={getLayoutDirectionGutter()}
				>
					<div
						className={`app-content ${
							isNavTop ? "layout-top-nav" : ""
						}`}
					>
						<PageHeader
							display={currentRouteInfo?.breadcrumb}
							title={currentRouteInfo?.title}
						/>
						<Content>
							{userData?.role?.system !== 1 &&
							!user?.primary_property?.legal_data &&
							history.location.pathname !==
								"/app/verification-portal" ? (
								<Alert
									message={t(
										"Complete the Know Your Partner (KYP) form"
									)}
									description={
										<>
											<p>
												{t(
													"To comply with various legal and regulatory requirements, we're asking all accommodation partners for certain information. Please contact us if you have any questions"
												)}
												<a
													href="https://t.me/+998950631110"
													target="_blank"
												>
													{" "}
													{t("Partner Help")}
												</a>
											</p>
											<Button
												type="primary"
												onClick={() =>
													history.push(
														"/app/verification-portal"
													)
												}
											>
												{t("Complete the form")}
											</Button>
										</>
									}
									type="warning"
									showIcon
									style={{ marginBottom: 20 }}
								/>
							) : (
								""
							)}

							{userData?.role?.system !== 1 &&
							!user?.telegram_group_id ? (
								<Alert
									type="info"
									message={t("Connect Telegram notification")}
									description={
										<>
											<p>
												{t(
													"Open a group on Telegram, add the  "
												)}
												<a
													href="https://t.me/bh_notifier_bot"
													target="_blank"
												>
													@bh_notifier_bot
												</a>
												{t(
													" bot to it, and click the connect button!"
												)}
											</p>
										</>
									}
									showIcon
									style={{ marginBottom: 20 }}
								/>
							) : (
								""
							)}

							<AppViews />
						</Content>
					</div>
					<Footer />
				</Layout>
			</Layout>
			{isMobile && <MobileNav />}
			{/* {isMobile && <MobileNavBottom />} */}
		</Layout>
	);
};

const mapStateToProps = ({ theme }) => {
	const { navCollapsed, navType, locale } = theme;
	return { navCollapsed, navType, locale };
};

export default connect(mapStateToProps)(React.memo(AppLayout));
