import Footer from "components/layout-components/Footer";
import BackButton from "components/shared-components/BackButton";
import { t } from "i18n";
import { useState } from "react";
import { PDFReader } from "reactjs-pdf-reader";

function TermsOfUse() {
	const [scale, setScale] = useState(1);

	return (
		<div className="container">
			{" "}
			<div className="d-flex align-items-center mt-4">
				<BackButton />
				<h4 className="m-0 p-0">{t("back")}</h4>
			</div>
			<PDFReader
				showAllPage={true}
				url={"/Terms_of_use.pdf"}
				scale={scale}
			/>
			<Footer />
		</div>
	);
}

export default TermsOfUse;
