import { Button, Form, message, Steps } from "antd";
import { LegalDataApi } from "api/LegalDataApi";
import { t } from "i18n";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { authenticated } from "reduxs/actions/Auth";
import { getTOKEN } from "reduxs/reducers/Auth";
import { MessagesService } from "services/Messages";
import BillingDetails from "./components/BillingDetails";
import Confirmation from "./components/Confirmation";
import PropertyDetails from "./components/PropertyDetails";
import RegistrationDetails from "./components/RegistrationDetails";

export default function VerificationPortal() {
	const [form] = Form.useForm();
	const [current, setCurrent] = useState(0);
	const history = useHistory();
	const dispatch = useDispatch();
	const steps = [
		{
			title: t("Property details"),
			content: <PropertyDetails />,
		},
		{
			title: t("Registration details"),
			content: <RegistrationDetails />,
		},
		{
			title: t("Billing Details"),
			content: <BillingDetails />,
		},
		{
			title: t("Confirmation"),
			content: (
				<Confirmation
					form={form}
					current={current}
					setCurrent={setCurrent}
				/>
			),
		},
	];
	const user = useSelector((state) => state.auth.user);

	const { Step } = Steps;

	const onFinish = () => {
		LegalDataApi.createLegalDataProperty({
			tin: String(form.getFieldValue().tin),
			name: form.getFieldValue().property_name,
			account: String(form.getFieldValue().account),
			mfi: String(form.getFieldValue().mfi),
			okonx: String(form.getFieldValue().okonx),
			region: form.getFieldValue().region,
			phone_number: form.getFieldValue().phone_number,
			bank_name: form.getFieldValue().bank_name,
		})
			.then(({ data }) => {
				const token = getTOKEN();
				MessagesService.success(t("Legal data sent successfully"));
				dispatch(
					authenticated(token, {
						...user,
						legal_data_id: data.id,
						legal_data: data,
					})
				);
				history.push("/");
			})
			.catch((err) => {
				if (
					err.response?.status === 422 &&
					err.response?.data?.errors
				) {
					Object.entries(err.response.data.errors).forEach(
						([field, message]) => {
							MessagesService.error(t(message));
						}
					);
				} else {
					MessagesService.error(t("Error sending legal data"));
				}
			});
	};

	return (
		<>
			<h3>{t("Partner verification")}</h3>
			<p>
				{t(
					"In order to comply with various legal and regulatory requirements, we need to collect and verify some information about you and your property. Please fill in and submit this form."
				)}
			</p>
			<div>
				<Steps
					className="site-navigation-steps p-4"
					onChange={(_current) => {
						if (_current < current) {
							return setCurrent(_current);
						}
						if (current + 1 < _current) return;
						form.validateFields()
							.then(() => {
								setCurrent(_current);
							})
							.catch(() => {
								message.warn(t("Please fill required fields"));
							});
					}}
					type="inline"
					current={current}
					labelPlacement="vertical"
				>
					{steps.map((item) => (
						<Step key={item.title} title={item.title} />
					))}
				</Steps>
				<div>
					<Form
						form={form}
						initialValues={{
							property_name:
								form.getFieldsValue().property_name ||
								user?.primary_property?.name,
							region:
								form.getFieldsValue().region ||
								user?.primary_property?.address?.city_id,
							address:
								form.getFieldsValue().address ||
								user?.primary_property?.address?.addressLine,
							...form.getFieldsValue(),
						}}
					>
						{steps[current].content}
					</Form>
				</div>
				<div
					style={{
						marginTop: 24,
					}}
				>
					{current > 0 && (
						<Button
							style={{
								margin: "0 8px",
							}}
							onClick={() => setCurrent(current - 1)}
						>
							{t("Previous")}
						</Button>
					)}
					{current < steps.length - 1 && (
						<Button
							type="primary"
							onClick={() => {
								form.validateFields()
									.then((data) => {
										setCurrent(current + 1);
									})
									.catch(() => {
										message.warn(
											t("Please fill required fields")
										);
									});
							}}
						>
							{t("Next")}
						</Button>
					)}
					{current === steps.length - 1 && (
						<Button type="primary" onClick={onFinish}>
							{t("Submit")}
						</Button>
					)}
				</div>
			</div>
		</>
	);
}
