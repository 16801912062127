import Loading from "components/shared-components/Loading";
import { ADMIN_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";
import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { AdminViews } from "views/admin-views";
import ConnectTelegram from "./connect-telegram";
import Dashboard from "./dashboard";
import Invoices from "./finance/invoices";
import PropertyList from "./properties/propertiesList";
import BookingCalendar from "./property/calendar/index.tsx";
import FacilitiesService from "./property/facilities-services";
import GeneraInfo from "./property/GeneralInfo";
import Marketing from "./property/marketing";
import NearbyPlaces from "./property/nearby-places";
import UploadPhotos from "./property/photos";
import Policy from "./property/Policy";
import RoomAmenities from "./property/room-aminities";
import RoomDetails from "./property/room-details";
import CreateRoom from "./property/room-details/CreateRoom";
import EditRoom from "./property/room-details/EditRoom";
import CreateRate from "./rates/create-rate";
import EditRate from "./rates/edit-rate";
import RatePlanes from "./rates/rate-planes";
import Reservations from "./reservations";
import ReservationItem from "./reservations/ReservationItem";
import Reviews from "./reviews";
import Settings from "./settings";
import VerificationPortal from "./verification-portal";

export const AppViews = () => {
	return (
		<Suspense fallback={<Loading cover="content" />}>
			<Switch>
				<Route
					path={`${APP_PREFIX_PATH}/dashboard`}
					component={Dashboard}
				/>
				{/* ROOM-DETAILS ROUTERS */}
				<Route
					path={`${APP_PREFIX_PATH}/room-details`}
					component={RoomDetails}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/room-create`}
					component={CreateRoom}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/room-edit/:id`}
					component={EditRoom}
				/>
				{/* END ROOM-DETAILS ROUTERS */}
				<Route
					path={`${APP_PREFIX_PATH}/room-amenities`}
					component={RoomAmenities}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/calendar`}
					component={BookingCalendar}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/facilities-services`}
					component={FacilitiesService}
				/>
				{/* RATES ROUTERS */}
				<Route
					path={`${APP_PREFIX_PATH}/rate-plans`}
					component={RatePlanes}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/create-rate`}
					component={CreateRate}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/edit-rate/:id`}
					component={EditRate}
				/>
				{/* END RATES ROUTERS */}
				<Route
					path={`${APP_PREFIX_PATH}/nearby-places`}
					component={NearbyPlaces}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/invoices`}
					component={Invoices}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/connect-telegram`}
					component={ConnectTelegram}
				/>
				<Route
					path={`${
						APP_PREFIX_PATH + ADMIN_PREFIX_PATH
					}/reservations/:id`}
				>
					<ReservationItem role="admin" />
				</Route>

				<Route
					path={`${APP_PREFIX_PATH}/reservations/:id`}
					component={ReservationItem}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/reservations`}
					component={Reservations}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/chat`}
					component={lazy(() => import(`./inbox/chat`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/photos`}
					component={UploadPhotos}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/reviews`}
					component={Reviews}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/properties`}
					component={PropertyList}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/general-info`}
					component={GeneraInfo}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/policies`}
					component={Policy}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/marketing`}
					component={Marketing}
				/>

				<Route
					path={`${APP_PREFIX_PATH}/settings`}
					component={Settings}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/verification-portal`}
					component={VerificationPortal}
				/>
				<Route
					path={`${APP_PREFIX_PATH + ADMIN_PREFIX_PATH}`}
					component={AdminViews}
				/>

				<Redirect to={`${APP_PREFIX_PATH}/dashboard`} />
			</Switch>
		</Suspense>
	);
};

export default React.memo(AppViews);
