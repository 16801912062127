import { Alert, Button, Card, Col, Form, Input, Row } from "antd";
import { LegalDataApi } from "api/LegalDataApi";
import { PropertyApi } from "api/PropertyApi";
import Loading from "components/shared-components/Loading";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MessagesService } from "services/Messages";

export default function GeneraInfo() {
	const [info, setInfo] = useState({});
	const [data, setData] = useState();
	const [loader, setloader] = useState(false);
	const [form] = Form.useForm();
	useEffect(async () => {
		setloader(true);
		await PropertyApi.getPrimaryProperty()
			.then((data) => {
				setInfo(data);
				setloader(false);
			})
			.catch((err) => {
				console.log(err);
			});
		await LegalDataApi.getLegalPropertys()
			.then((data) => {
				setData(data?.data);
				setloader(false);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);
	const onFinish = () => {
		PropertyApi.putProperty({
			name: form.getFieldValue().name,
		})
			.then((newData) => {
				setInfo({
					...data,
					name: newData?.data?.moderation_data.name,
					moderation_data: newData?.data?.moderation_data,
				});
				MessagesService.success();
			})
			.catch((err) => {
				MessagesService.error();
			});
	};

	if (loader) {
		return <Loading />;
	}
	return (
		<>
			<h4>{t("General info")}</h4>
			<Card>
				<Col xs={24} sm={24} md={24} lg={12}>
					<Form form={form} onFinish={onFinish}>
						<Form.Item
							name="name"
							label={t("Property name")}
							labelCol={{ span: 24 }}
							initialValue={info.name}
							rules={[
								{
									require: true,
								},
							]}
						>
							<Input />
						</Form.Item>

						{info?.moderation_data?.name ? (
							<Alert
								message={t("Warning")}
								description={
									<>
										<span>
											{t(
												"The name of the hotel is being moderated and will change within 24 hours."
											)}
										</span>

										<span style={{ display: "block" }}>
											<b>{t("New name")}: </b>
											{info?.moderation_data?.name}
										</span>
									</>
								}
								type="warning"
								showIcon
								closable
								style={{ marginBottom: 20 }}
							/>
						) : (
							""
						)}
						<Form.Item>
							<Button type="primary" htmlType="submit">
								{t("Save")}
							</Button>
						</Form.Item>
					</Form>
					<div className="d-flex justify-content-between align-items-center">
						<h3>{t("Property legal data")}</h3>
					</div>
					{data ? (
						<div>
							<Card>
								<Col xs={24} sm={24} md={24} lg={24}>
									<div className="d-flex justify-content-between align-items-center">
										<h4>{t("Property details")}</h4>
									</div>
								</Col>
								<hr style={{ marginBottom: 20 }} />
								<Row justify="space-between">
									<Col xs={12} sm={12} md={12} lg={12}>
										<h5>{t("Name")}:</h5>
									</Col>
									<Col xs={12} sm={12} md={12} lg={12}>
										{data?.name}
									</Col>
								</Row>
								<Row justify="space-between">
									<Col xs={12} sm={12} md={12} lg={12}>
										<h5>{t("Phone number")}:</h5>
									</Col>
									<Col xs={12} sm={12} md={12} lg={12}>
										{data?.phone_number}
									</Col>
								</Row>
							</Card>
							<Card>
								<Col xs={24} sm={24} md={24} lg={24}>
									<div className="d-flex justify-content-between align-items-center">
										<h4>{t("Registration details")}</h4>
									</div>
								</Col>
								<hr style={{ marginBottom: 20 }} />
								<Row justify="space-between">
									<Col xs={12} sm={12} md={12} lg={12}>
										<h5>{t("IFUT code")}:</h5>
									</Col>
									<Col xs={12} sm={12} md={12} lg={12}>
										{data?.tin}
									</Col>
								</Row>
								<Row justify="space-between">
									<Col xs={12} sm={12} md={12} lg={12}>
										<h5>{t("TIN")}:</h5>
									</Col>
									<Col xs={12} sm={12} md={12} lg={12}>
										{data?.okonx}
									</Col>
								</Row>
							</Card>
							<Card>
								<Col xs={24} sm={24} md={24} lg={24}>
									<div className="d-flex justify-content-between align-items-center">
										<h4>{t("Billing Details")}</h4>
									</div>
								</Col>
								<hr style={{ marginBottom: 20 }} />
								<Row justify="space-between">
									<Col xs={12} sm={12} md={12} lg={12}>
										<h5>{t("Bank name")}:</h5>
									</Col>
									<Col xs={12} sm={12} md={12} lg={12}>
										{data?.bank_name}
									</Col>
								</Row>
								<Row justify="space-between">
									<Col xs={12} sm={12} md={12} lg={12}>
										<h5>{t("MFO")}:</h5>
									</Col>
									<Col xs={12} sm={12} md={12} lg={12}>
										{data?.mfi}
									</Col>
								</Row>
								<Row justify="space-between">
									<Col xs={12} sm={12} md={12} lg={12}>
										<h5>{t("Account")}:</h5>
									</Col>
									<Col xs={12} sm={12} md={12} lg={12}>
										{data?.account}
									</Col>
								</Row>
							</Card>
						</div>
					) : (
						<Alert
							type="info"
							message="You can enter your legal information by clicking the button below!"
							description={
								<>
									<Button type="primary">
										<Link to="/app/verification-portal">
											Complete the form
										</Link>
									</Button>
								</>
							}
							showIcon={true}
						/>
					)}
				</Col>
			</Card>
		</>
	);
}
